import React from "react";
import Layout from "../../components/Layout";
import { Row } from "reactstrap";
import { urlDescription } from '../../utils/pageDescription'
import "./styles.scss";

const ConstructionMaterials = () => {
  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="news-single-wrapper">
        <div className="news-single-container">
          <Row className="news-single-row">
            <h1>Construction Materials</h1>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default ConstructionMaterials;
